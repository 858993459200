import React from "react"
import "../styles/index.scss"
import Footer from "./Footer"
import Header from "./Header"
import { Helmet } from "react-helmet"

import layoutStyles from "./layout.module.scss"

const Layout = props => (
  <div className={layoutStyles.wrapper}>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Kronos Seguros</title>
    </Helmet>
    <div id="page-container">
      <Header />
      <div id="content-wrap">
        <main>{props.children}</main>
      </div>
    </div>
    <Footer />
  </div>
)

export default Layout
