// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-service-template-js": () => import("./../src/templates/ServiceTemplate.js" /* webpackChunkName: "component---src-templates-service-template-js" */),
  "component---src-templates-service-category-template-js": () => import("./../src/templates/ServiceCategoryTemplate.js" /* webpackChunkName: "component---src-templates-service-category-template-js" */),
  "component---src-pages-acerca-js": () => import("./../src/pages/acerca.js" /* webpackChunkName: "component---src-pages-acerca-js" */),
  "component---src-pages-contacto-js": () => import("./../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nosotros-js": () => import("./../src/pages/nosotros.js" /* webpackChunkName: "component---src-pages-nosotros-js" */),
  "component---src-pages-servicios-js": () => import("./../src/pages/servicios.js" /* webpackChunkName: "component---src-pages-servicios-js" */)
}

