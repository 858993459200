import React from "react"
import { Link } from "gatsby"

import footerStyles from "./footer.module.scss"

const Footer = () => {
  const date = new Date()
  const year = date.getFullYear()
  return (
    <footer className={footerStyles.footer}>
      <p>Kronos todos los derechos reservados, © {year}</p>
    </footer>
  )
}

export default Footer
