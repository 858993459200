import React from "react"
import Headroom from "react-headroom"
import Fade from "react-reveal/Fade"

import logo from "../assets/kronos.png"
import logoWhite from "../assets/logo-white.svg"
import { Link, graphql, useStaticQuery } from "gatsby"

import headerStyles from "./header.module.scss"

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          author
        }
      }
    }
  `)

  return (
    <div className={headerStyles.headerWrapper}>
      <Fade top>
        <header className={headerStyles.header}>
          <h1 className="headerlogo--color">
            <Link className={headerStyles.title} to="/">
              <img className={headerStyles.logo} src={logo} />
            </Link>
          </h1>

          {/*}
          <h1 className="headerlogo--white">
            <Link className={headerStyles.title} to="/">
              <img className={headerStyles.logo} src={logoWhite} />
            </Link>
          </h1>
  */}
          <nav>
            <ul className={headerStyles.navList}>
              <li>
                <Link
                  className={headerStyles.navItem}
                  activeClassName={headerStyles.navItemActive}
                  to="/nosotros"
                >
                  Nosotros
                </Link>
              </li>
              <li>
                <Link
                  className={headerStyles.navItem}
                  activeClassName={headerStyles.navItemActive}
                  to="/servicios"
                >
                  Servicios
                </Link>
              </li>
              {/*
              <li>
                <Link
                  className={headerStyles.navItem}
                  activeClassName={headerStyles.navItemActive}
                  to="/about"
                >
                  Campañas
                </Link>
              </li>
              */}
              {/*
              <li>
                <Link
                  className={headerStyles.navItem}
                  activeClassName={headerStyles.navItemActive}
                  to="/blog"
                >
                  Cotiza
                </Link>
              </li>
              */}
              <li>
                <Link
                  className={headerStyles.navItem}
                  activeClassName={headerStyles.navItemActive}
                  to="/contacto"
                >
                  Contacto
                </Link>
              </li>
            </ul>
          </nav>
        </header>
      </Fade>
    </div>
  )
}

export default Header
